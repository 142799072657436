.tooltip {
    position: relative;
    display: inline-block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    width: 120px;
    background-color: #333;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .4);
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    top: -5px;
    right:-230%;
    position: absolute;
    z-index: 1;
    transition: .3s;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    opacity: 1;
    visibility: visible;
  }