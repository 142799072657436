.sidebar__wrapper {
  position: fixed;
  width: 79px;
  height: 100vh;
  background-color: var(--primary);
  border-left: 1px solid var(--primary-500);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 401;
}

.link-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.sb-logo {
  height: 5rem;
  padding: 0.5rem;
}

.events-link {
  width: 100%;
}