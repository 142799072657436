.button {
    margin: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--highlight);
    border: 1px solid var(--highlight);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:hover{
 
    background-color: var(--highlight-hover);
    border-color: var(--highlight-hover);
  }

.button:hover:after {
    width: 100%;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button-40 {
    width: 40%;
}

.button-50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.less-margin {
    margin: .3rem;
}

.button-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.button-primary:hover{
    background-color: var(--primary-500);
}


.button-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.button-secondary:hover{
    background-color: var(--secondary-500);
}

.button-danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.button-danger:hover{
    background-color: #770808;
}

.button-green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.button-green:hover {
    background-color: #10640d;
   color: white;
}


.button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.button-warning:afthoverer{
    background-color: #e0a800;
}

.button-outline-primary{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.button-outline-primary:hover{
    background-color: white;
}

.button-outline-danger{
    background-color: transparent;
    border: 1px solid #B70F0F;
    color: #B70F0F;
}
.button-outline-danger:hover{
    background-color: #B70F0F;
}

.button-outline-danger:hover {
    color: white;
}



.button-disabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.button-disabled:hover {
    background-color: gray;
}




