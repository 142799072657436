.auth_bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--secondary);
}

.auth-wrapper {
  width: 90%;
  max-width: 500px;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-card,
.forgot-card {
  background-color: transparent;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  box-shadow: none;
  margin-top: -20px;
}

.auth-logo {
  width: 100%;
  max-width: 420px;
  margin-bottom: 4rem;
}

.login-card h1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  text-align: left;
  font-size: 1.6rem;
  margin-left: 10%;
  color: white;
}
.login-card p {
  margin-left: 10%;
  margin-top: 0;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  color: white;
}

.login-form {
  display: flex;
  flex-wrap: wrap;
}

.auth-input {
  background-color: transparent;
  outline: none;
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid #ffffff66;
  color: white;
}

.auth-input::placeholder {
  color:#ffffff66;
  font-size: 1.6rem;
}

.login-btn {
  font-size: 2rem;
  font-weight: 400;
  width: 80%;
}

.enrol-btn {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
  width: 30%;
}

.enrol-cta-container {
  margin: 7px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enrol-cta-container p {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.forgot-card {
  display: flex;
  flex-direction: column;
}

.reset-sent-notice {
  color: white;
  margin: 0;
  padding: 10px 20px;
}

.spinner-center {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.auth-spinner {
  margin-top: 5rem;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}
