.main-nav__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.nav-links {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    list-style: none;

}

.nav-button {
    border: none;
    width: 100%;
}

.nav-links li a, .nav-button {
    display: block;
    color: white;
    text-decoration: none;
    margin: 0;
    padding: 15px;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: transparent;
    border-bottom: 1px solid var(--primary-300);
    transition: .6s;
    z-index: -1;
}

.nav-links li a:hover,
.nav-button:hover,
.nav-links li a:active, 
.nav-button:active, 
.nav-links li a:focus, 
.nav-button:focus 
{
    background-color: var(--primary-500);
}
