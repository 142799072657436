.playerWrapper {
    position: relative;
    width: 100%;
    max-width: 100rem;
  }
   
  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
  }

  .react-player video:focus {
      outline: none;
  }

  .playbtn, .fullscreenBtn {
    position: absolute;
    bottom: 1.5rem;
    left: 1%;
    background-color: var(--primary);
    border: 0px solid var(--primary-500);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    color: var(--secondary);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    transition: .3s;
    box-shadow: 0 .3 .3 rgba(0, 0, 0, .4);
}

.fullscreenBtn {
    height: 4rem;
    width: 4rem;
    left: calc(1% + 6rem);
}

.playbtn:hover, .fullscreenBtn:hover {
    opacity: 1;
}
