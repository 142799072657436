@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@100;200;300;400;500;600;700&family=Urbanist:wght@100;200;300;400;500;600;800;900&display=swap');

:root {
  --primary-100: #3eaed7;
  --primary-300: #0d8bb9;
  --primary: #0E9ACD;
  --primary-500: #0b7ba4;

  --secondary-100: #355f7b;
  --secondary: #03375A;
  --secondary-500: #022c48;

  --highlight: #FA9620;
  --highlight-hover: #af6916;

  --input-color: #797979;
  --input-border: #8c8c8c;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: var(--primary);

  --group-color: #333;
  --group-border: var(--input-border);
  --group-background: #ededed;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

  --alert: #D0342C;
  --warning: #ffeb3b;
  --success: #4E9B47;

}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  color: var(--primary);
  background-color: #f5f5f5;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}

a {
  color: var(--primary);
  text-decoration: none;
}

.clear-header {
  padding-top: 90px !important;
}

a:hover {
  color: var(--primary-500);
}

#root {
  width: 100%;
  display: flex;
}

.text-error {
  font-size: 1.2rem;
  color: darkred;
}

.page-title {
  margin: 2rem 3.5rem;
  font-weight: 200;
  font-size: 4rem;
  color: grey;
  display: block;
  width: 100%;
  border-bottom: .1rem solid var(--primary);
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}